import {
  shape,
  arrayOf,
  number,
  string,
  bool,
  oneOfType,
  oneOf,
} from 'prop-types';

export const projectType = shape({
  id: oneOfType([string, number]), // FIXME: it sometimes comes as a string, but others as a number, it should always be a string (graphQLID type)
  name: string,
  value: string,
  color: string,
});

export const clientsWithNestedProjectsListType = arrayOf(
  shape({
    clientName: string,
    clientId: oneOfType([string, number]),
    projects: arrayOf(projectType),
  }),
);

export const clientProjectType = shape({
  client: shape({
    clientName: string,
  }),
  project: projectType,
});

export const timeEntryType = shape({
  id: string,
  description: string,
  projectId: number,
  startedAt: string,
  finishedAt: string,
  billable: bool,
  enabled: bool,
  tags: arrayOf(
    shape({
      name: string,
    }),
  ),
  isOverlapped: bool,
});

export const tagType = shape({
  id: oneOfType([string, number]),
  name: string,
  color: string,
});

export const tagListType = arrayOf(tagType);

export const sessionType = shape({
  user: shape({
    authToken: string,
    email: string,
    name: string,
  }),
});

const timeEntriesGroupedBySecondField = shape({
  label: string,
  totalTime: string,
  timePercentage: number,
});

export const timeEntryReportResultType = shape({
  id: string,
  value: number,
  totalTime: string,
  billableTime: string,
  nonBillableTime: string,
  clientName: string,
  projectName: string,
  color: string,
  timeEntriesGroupedBySecondField: arrayOf(timeEntriesGroupedBySecondField),
});

export const timeEntryWeeklyReportResultType = shape({
  key: string,
  total: number,
  projectName: string,
  clientName: string,
  userName: string,
  color: string,
  profilePicture: string,
  timeStatsGroupedByDate: arrayOf(
    shape({
      date: string,
      total: number,
    }),
  ),
  timeEntriesWeeklyGroupedSecondField: arrayOf(
    shape({
      key: number,
      total: number,
      label: string,
      timeStatsGroupedByDate: arrayOf(
        shape({
          date: string,
          total: number,
        }),
      ),
    }),
  ),
});

export const billableStatsGroupedByDateType = shape({
  date: string,
  billable: number,
  notBillable: number,
  total: number,
});

export const weeklyHoursTotalsType = shape({
  date: string,
  total: number,
});

export const timeStatsType = shape({
  total: number,
  billable: number,
});

export const listOfItemsType = shape({
  id: string,
  name: string,
});

export const roleType = shape({
  id: string,
  name: string,
  hierarchy: number,
});

export const roleListType = arrayOf(roleType);

export const userType = shape({
  id: string,
  name: string,
  contactEmail: string,
  enabled: bool,
  profilePicture: string,
  applicationId: number,
  userRole: roleType,
});

export const usersRolesType = shape({
  userId: number,
  userName: string,
  profilePicture: string,
  contactEmail: string,
  roleId: number,
  isUserSynched: bool,
  enabled: bool,
});

export const usersRolesPaginatedType = shape({
  usersRoles: arrayOf(usersRolesType),
  usersRolesCount: number,
});

export const userRoleType = shape({
  id: string,
  name: string,
  hierarchy: number,
});

export const timeEntryListType = arrayOf(
  shape({
    id: string,
    projectId: number,
    tagId: number,
    startedAt: string,
    finishedAt: string,
    description: string,
    billable: bool,
    duration: number,
    userId: number,
    project: projectType,
    client: shape({
      id: string,
      name: string,
    }),
  }),
);

export const favoriteEntryDataType = shape({
  id: oneOfType([string, number]),
  userId: oneOfType([string, number]),
  description: string,
  project: shape({
    id: oneOfType([string, number]),
    name: string,
    color: string,
  }),
  tag: shape({
    id: oneOfType([string, number]),
    name: string,
    color: string,
  }),
  client: shape({
    id: oneOfType([string, number]),
    name: string,
    color: string,
  }),
  billable: bool,
});

export const popoverPlacementOptionsType = oneOf([
  'auto',
  'auto-start',
  'auto-end',
  'top-start',
  'top',
  'top-end',
  'right-start',
  'right',
  'right-end',
  'bottom-start',
  'bottom',
  'bottom-end',
  'left-start',
  'left',
  'left-end',
]);

export const textAlignOptionsType = oneOf(['left', 'center', 'right']);

export const singleListItem = shape({
  id: string,
  name: string,
  color: string,
  clientName: string,
});

export const applicationType = shape({
  id: string,
  name: string,
});

export const studiosListType = shape({
  id: string,
  hibobid: number,
  name: string,
  count: number,
  studioClients: arrayOf(
    shape({
      id: string,
    }),
  ),
});

export const studiosClientsListType = shape({
  studioId: string,
  clientId: number,
});

export const applicationListType = arrayOf(applicationType);

export const userGroupsListType = arrayOf(
  shape({
    name: string,
    users: arrayOf(
      shape({
        name: string,
        id: number,
      }),
    ),
  }),
);
