const ROOT = '/';
const PRIVATE_ROUTE = '/app';
const APP_TIMER = '/app/timer';
const APP_SETTINGS_GENERAL = '/app/settings/general';
const APP_SETTINGS_PROFILE = '/app/settings/profile';
const APP_MEMBERS = '/app/members';
const APP_STUDIOS = '/app/studios';
const APP_MEMBERS_EDIT = '/app/members/edit';
const APP_MEMBERS_GENERAL = '/app/members';
const APP_MEMBERS_GROUPS = '/app/members/groups';
const APP_LOGOUT = '/app/logout';
const GRAPHQL = '/graphql';
const SUBSCRIPTIONS = '/subscriptions';
const APP_REPORTS_SUMMARY = '/app/reports/summary';
const APP_REPORTS_DETAILED = '/app/reports/detailed';
const APP_REPORTS_WEEKLY = '/app/reports/weekly';

const UNAUTHORIZED = '/401';
const NOT_FOUND_ERROR_PAGE = '/404';
const INTERNAL_SERVER_ERROR_PAGE = '/500';

const LOGIN_CALLBACK_PARAM = 'lc';

export {
  ROOT,
  APP_TIMER,
  APP_SETTINGS_GENERAL,
  APP_SETTINGS_PROFILE,
  APP_LOGOUT,
  PRIVATE_ROUTE,
  GRAPHQL,
  SUBSCRIPTIONS,
  LOGIN_CALLBACK_PARAM,
  APP_REPORTS_SUMMARY,
  APP_REPORTS_DETAILED,
  APP_REPORTS_WEEKLY,
  APP_MEMBERS,
  APP_STUDIOS,
  APP_MEMBERS_EDIT,
  UNAUTHORIZED,
  NOT_FOUND_ERROR_PAGE,
  INTERNAL_SERVER_ERROR_PAGE,
  APP_MEMBERS_GENERAL,
  APP_MEMBERS_GROUPS,
};
